import * as React from 'react';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import CategoryStore from "vanilli-shop-client/dist/stores/category-store";
import {inject, observer} from "mobx-react";
import {ICategoryDto} from "vanilli-shop-client/dist/models/category";
import {Link} from 'react-router-dom';
import routes from "../../routes";
import Loader from "../base/loader";

interface ICategoryListProps extends IBaseProps {
    categoryStore?: CategoryStore
}

@inject('categoryStore')
@observer
export default class CategoryList extends Base<ICategoryListProps> {
    componentDidMount() {
        this.props.categoryStore?.getCategories();
    }

    render() {
        return <section className="section-xl bg-white text-center" id="categories">
            <div className="shell">
                <div className="range range-50 pt-5">
                    <div className="cell-xs-12">
                        <h2 className="with-divider-red pt-5 pt-lg-0 pt-md-0 pt-sm-0">Shop by category</h2>
                        <div className="p text-width-medium">
                            <p className="big">Our clothing collection consists of many different products. Choose the desired product category
                            below.</p>
                        </div>
                    </div>
                    <div className="container col-12">{!this.props.categoryStore?.loading ? this.renderCategories() :
                        <Loader/>}</div>
                </div>
            </div>
        </section>;
    }

    private renderCategories(): JSX.Element {
        return <div
            className="range range-30 row">{this.props.categoryStore?.categories.map((category) => CategoryList.renderCategory(category))}</div>;
    }

    private static renderCategory(category: ICategoryDto): JSX.Element {
        const id = category.id;
        const name = category.name;
        const img = category.img || "https://via.placeholder.com/300";

        return <div key={id} className="col-lg-3 col-md-4 col-6 height-fill">
            <div className="thumbnail-card">
                <Link to={`${routes.categories}/${id}`}>
                    <img src={img} className="thumbnail-card-image" loading="lazy" alt={name}/>
                    <div className="thumbnail-card-body">
                        <p className="thumbnail-card-header">{name}</p>
                    </div>
                </Link>
            </div>
        </div>;
    }
}