import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "mobx-react";
import RootStore from "vanilli-shop-client/dist/stores/root-store";

const rootStore = new RootStore();

const stores = {
    rootStore: rootStore,
    blogStore: rootStore.blogStore,
    cartStore: rootStore.cartStore,
    categoryStore: rootStore.categoryStore,
    companyStore: rootStore.companyStore,
    countryStore: rootStore.countryStore,
    couponStore: rootStore.couponStore,
    orderStore: rootStore.orderStore,
    parameterStore: rootStore.parameterStore,
    productStore: rootStore.productStore,
    shippingStore: rootStore.shippingStore,
    translationStore: rootStore.translationStore
};

ReactDOM.render(
    <React.StrictMode>
        <Provider {...stores}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
