import React, {createRef, RefObject} from "react";

interface ICouponProps {
    onApply: (code: string) => void,
    loading?: boolean
}

export default class Coupon extends React.Component<ICouponProps> {
    private readonly codeRef: RefObject<HTMLInputElement>;

    constructor(props: ICouponProps) {
        super(props);
        this.codeRef = createRef();
    }

    render() {
        return <div className="coupon row">
            <div className="pr-2 col-7">
                <input ref={this.codeRef} type="password" className="form-control" placeholder="Coupon code"
                       required={true}/>
            </div>
            <div className="col-5">
                <button className="btn btn-back link link-primary link-return" disabled={this.props.loading}
                        onClick={async (e) => await this.initCouponApply(e)}>Apply<span className="fa-money pl-1"/>
                </button>
            </div>
        </div>;
    }

    private async initCouponApply(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const codeField = this.codeRef.current;
        const valid = codeField?.reportValidity();

        if (valid) {
            const code = codeField?.value;

            if (code) {
                await this.props.onApply(code);
            }
        }
    }
}