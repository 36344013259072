import * as React from 'react';
import {inject, observer} from "mobx-react";
import ProductDetailsBase, {IProductDetailsBaseProps} from "vanilli-shop-client/dist/components/product-details-base";
import Loader from "./base/loader";
import {Link} from 'react-router-dom';
import routes from "../routes";
import Error404 from "./404";
import {IProductDetails, IProductImgDto, Product} from "vanilli-shop-client/dist/models/product";
import {importScript, removeScript} from "vanilli-shop-client/dist/components/import-script";
import {IParameterValueDto, IParameterValueGroupDto} from "vanilli-shop-client/dist/models/parameter";

@inject('cartStore', 'parameterStore', 'productStore')
@observer
export default class ProductDetails extends ProductDetailsBase<IProductDetailsBaseProps> {
    async componentDidMount() {
        await super.componentDidMount();
        removeScript("/js/script.js");
        importScript("/js/script.js");
    }

    componentDidUpdate(prevProps: Readonly<IProductDetailsBaseProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.match.params.ref !== this.props.match.params.ref) {
            window.location.reload();
        }
    }

    render() {
        const productStore = this.props.productStore;
        const product = productStore.product;
        const backLink = product?.categoryId ? `${routes.categories}/${product.categoryId}` : routes.categoriesHash;

        return <section className="section-xl bg-periglacial-blue">
            <div className="shell pt-5">
                <Link to={backLink} className="btn btn-back link link-primary link-return"><span
                    className="fa-arrow-left"/>Back</Link>
                {!productStore.loading ? this.renderProductResult() : <Loader/>}
            </div>
        </section>;
    }

    private renderProductResult(): JSX.Element {
        return this.props.productStore.product ? this.renderProduct() : <Error404/>;
    }

    private renderProduct(): JSX.Element {
        const props = this.props;
        const product = props.productStore.product;
        const imgs = product?.imgs;

        return <article className="product-single text-center">
            <div className="row w-100">
                <div className="col-12 col-md-6 order-1 order-md-0">
                    <div className="product-single-left">
                        <div className="slick-carousel-product">
                            <div data-arrows="false" data-dots="false" data-loop="true" data-swipe="true" data-items="1"
                                 data-child="#child-carousel" data-for="#child-carousel"
                                 className="slick-slider carousel-parent">{imgs?.map((img) => ProductDetails.renderImg(img))}</div>
                            <div id="child-carousel" data-for=".carousel-parent" data-arrows="false" data-loop="true"
                                 data-dots="false" data-swipe="true" data-items="3" data-xxs-items="4" data-xs-items="4"
                                 data-sm-items="3" data-md-items="4" data-lg-items="4" data-slide-to-scroll="1"
                                 className="slick-slider carousel-child">{imgs?.map((img) => ProductDetails.renderImg(img))}</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 order-0 order-md-1">
                    <div className="product-single-body">
                        <p className="product-single-title">{product?.name}</p>
                        <div className="product-single-price-wrap">
                            {product && ProductDetails.renderPrice(product)}
                            {this.renderStock()}
                        </div>
                        <div className="product-single-text text-justify"
                             dangerouslySetInnerHTML={{__html: product?.description || ""}}/>
                        {!props.parameterStore.loading ? this.renderParameters() : <Loader className="text-center"/>}
                        <div className="row text-center">
                            {product && <div className="col-12 col-lg-6">
                                <button className="btn btn-12 btn-1e2"
                                        onClick={() => props.cartStore.addToCart(product)}>
                                    <span className="">Add to cart</span>
                                    <span className="fa-plus pl-1"/>
                                </button>
                            </div>}
                            <div className="col-12 col-lg-6">
                                <Link to={routes.cart}>
                                    <button className="btn btn-12blk btn-1e2blk">Checkout <span
                                        className="fa-shopping-cart pl-1"/>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>;
    }

    private static renderPrice(product: IProductDetails): JSX.Element {
        if (product.discountPrice) {
            return <div className="price">
                <p className="text-strike">{product.price.toFixed(2)}€</p>
                <p className="text-danger">{product.currentPrice.toFixed(2)}€</p>
            </div>
        }

        return <p className="price">{product.currentPrice.toFixed(2)}€</p>;
    }

    private renderParameters(): JSX.Element {
        return <div
            className="w-100">{this.props.parameterStore.parameters.map((parameter) => this.renderParameter(parameter))}</div>;
    }

    private renderParameter(parameter: IParameterValueGroupDto): JSX.Element {
        const name = parameter.parameterName;

        return <div key={name} className="dropdown show text-center">
            <a className="btn btn-back link link-primary link-next dropdown-toggle" href="#" role="button"
               id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false">{this.renderSelectedParameterValue(parameter)}<i className="fas fa-sort-down"/></a>
            <div className="dropdown-menu w-100 text-center"
                 aria-labelledby="dropdownMenuLink">{parameter.parameterValues.map((parameterValue) => this.renderParameterValue(parameterValue, name))}</div>
        </div>;
    }

    private renderSelectedParameterValue(parameter: IParameterValueGroupDto): string {
        const name = parameter.parameterName;
        const selected = this.props.parameterStore.selectedParameters.find(x => x.name === name);

        if (selected) {
            return selected.value;
        }

        return `${name.toLowerCase()}`;
    }

    private renderParameterValue(parameterValue: IParameterValueDto, parameterName: string): JSX.Element {
        const id = parameterValue.parameterValueId;
        const stock = parameterValue.stock;

        return <a key={id} className="dropdown-item" href="javascript:void(0)" hidden={stock != null && stock < 1}
                  onClick={() => this.props.parameterStore.selectParameterValue(id, parameterName)}>{parameterValue.parameterValueName}</a>;
    }

    private renderStock(): JSX.Element {
        const product = this.props.productStore.product;
        const hasStock = product && Product.hasStock(product);
        return hasStock ? <small>(Available)</small> : <small className="text-danger">(Unavailable)</small>;
    }

    private static renderImg(img: IProductImgDto): JSX.Element {
        const path = img.path || "https://via.placeholder.com/300";

        return <div key={path} className="item">
            <img src={path} alt={path}/>
        </div>;
    }
}