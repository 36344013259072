import * as React from 'react';
import Background2 from '../assets/images/img2.jpg';


export default class Terms extends React.Component {
    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image">
            <div className="cell-xs-12 text-center pt-10 text-white pb-5">
                <h2 className="with-divider-red pt-5 text-white">Terms and conditions</h2>
                <div className="p text-width-medium">
                    <p className="big text-justify pt-5">
                        The owner of the online Fast Iron (https://fast-iron.com, hereinafter referred to as the online
                        store) is Fast Iron OÜ (registry code 14453633), located at Linnu tn 41/4 Tartu, Tartumaa 50416.
                        <br/><br/><br/>
                        <strong>Validity of contract of sale, product and price information</strong>
                        <br/><br/>
                        The conditions of sale apply to purchases of goods from the online store. The prices of the
                        products sold in the online store are indicated next to the products. A fee for shipping is
                        added to the price. The shopping cost depends on the location of the purchaser and the shipping
                        method. The shipping cost is displayed when the purchaser places the order. ProductModel
                        information is provided immediately adjacent to the product in the online store. Fast Iron OÜ
                        only accepts payments in euros (EUR).
                        <br/><br/>
                        <strong>Placing an order</strong><br/><br/>

                        Add the desired products to your shopping basket by locating the product you're after,
                        understanding all the enlisted details next to the product, picking the right size and clicking
                        "Add to cart". Go to "CartModel" section and, fill in all of the required fields, accept the
                        terms and conditions/privacy policy and choose the most suitable shipping method to complete the
                        order. The total cost is then displayed on the screen.
                        This can be paid via an online bank link or by using a credit card (VISA/MasterCard). Fast Iron
                        OÜ is vouched to process your personal data and delivers your information to authorized payment
                        processor Maksekeskus AS.
                        The contract enters into force when the amount payable is transferred to the bank account of the
                        online store. If we cannot deliver the ordered goods due to stock running out or any other
                        reason, the purchaser will be informed as soon as possible and the money paid (including the
                        shipping cost) will be refunded promptly, but no later than within 14 days of receiving the
                        notification.
                        <br/><br/><br/>
                        <strong>Shipment</strong><br/><br/>

                        Goods are shipped to the following countries: Åland Islands, Albania, Andorra, Armenia, Aruba,
                        Austria, Azerbaijan, Belarus, Belgium, Bosnia and Herzegovina, Bulgaria, Croatia, Curaçao,
                        Cyprus, Czech Republic, Denmark, Republic of Estonia, Faroe Islands, Finland, France, Georgia,
                        Germany, Gibraltar, Greece, Guernsey, Holy See (Vatican City State), Hungary, Iceland, Ireland,
                        Isle of Man, Italy, Jersey, Kazakhstan, Latvia, Liechtenstein, Lithuania, Luxembourg, The Former
                        Yugoslav Republic of Macedonia, Malta, Republic of Moldova, Monaco, Montenegro, Netherlands,
                        Netherlands Antilles, Norway, Poland, Portugal, Romania, Russian Federation, San Marino, Serbia,
                        Sint Maarten, Slovakia, Slovenia, Spain, Svalbard and Jan Mayen, Sweden, Switzerland, Turkey,
                        Ukraine and United Kingdom.
                        <br/><br/>
                        The purchaser bears the shipping cost and the respective price information is displayed next to
                        the shipping method.
                        Shipping is provided by partners Itella SmartPOST and Omniva. Package is delivered to the
                        customer by using desired partner's parcel terminal (Itella SmartPOST or Omniva) or by Omniva's
                        courier. All shipments within Estonia generally arrive at the destination specified by the
                        purchaser with in 3-7 business days of the date of entry into force of the contract of sale via
                        parcel terminal and shipments to the other countries that are listed above, can take up to 30
                        days.
                        We have the right to ship goods in up to 45 calendar days in exceptional cases.
                        <br/><br/><br/>
                        <strong>Right of withdrawal</strong><br/><br/>

                        After receiving the order, the purchaser has the right to withdraw from the contract entered
                        into with the e-store within 14 days.
                        The right of withdrawal does not apply if the purchaser is a legal person. To exercise the
                        14-day right of withdrawal, you must not use the ordered goods in any way other than is
                        necessary to ensure the nature, characteristics and functioning of the goods in the same way you
                        would be allowed to test the goods in an actual store.
                        If the goods have been used for any purpose other than is necessary to ensure the nature,
                        characteristics and functioning of the goods, or if there are any signs of use or wear and tear,
                        the online store has the right to lower the amount returned in accordance with the decrease in
                        the value of the goods.
                        To return the goods, you must submit a declaration of withdrawal (the form for which can be
                        found here: declaration of withdrawal) to the e-mail address info@fast-iron.com within 14 days
                        of receiving the goods. The purchaser shall bear the cost of returning the goods, except in
                        cases where the reason for the return lies in the fact that a refundable product does not comply
                        with the order (e.g. a wrong or defective product).
                        The purchaser must return the goods within 14 days of the submission of the declaration of
                        withdrawal or submit evidence that they handed over the goods to the carrier within the
                        aforementioned period. Upon receiving the returned goods, the online store shall return to the
                        purchaser, immediately but no later thant after 14 days, all of the payments received from the
                        purchaser based on the contract.
                        The online store has the right to refuse to make the refund until the goods being returned are
                        received or until the buyer has provided proof of returning the products, whichever occurs
                        first. If the buyer has clearly chosen a different form of shipment than the cheapest usual form
                        of shipment offered by the online store, the store is not required to compensate the cost
                        exceeding the usual shipping cost.
                        The online store has the right to withdraw from the sale and demand that the goods be returned
                        by the purchaser if the marked price of the goods in the online store is significantly lower
                        than the market price of the goods due to an error.
                        <br/><br/><br/>
                        <strong>Defective goods</strong><br/><br/>

                        The online store is responsible for the non-compliance of goods sold to a purchaser with the
                        terms and conditions of the contract or for deficiencies which already existed at the time of
                        delivery and which occur within two years of delivering the goods to the purchaser. Within the
                        first six months of delivery it is assumed that the defect was present at the time of delivery.
                        It is the online store's responsibility to prove otherwise. The purchaser has the right to turn
                        to the online store within two months of the occurrence of a defect by e-mailing
                        info@fast-iron.com or calling +372 55639148. The online store is not liable for any defects
                        arising delivering the goods to the purchaser.
                        If goods bought from the online store have defects for which the online store is responsible,
                        the online store will repair or replace the defective goods. If the goods cannot be repaired or
                        replaced, the online store shall return to the purchaser all of the payments involved in the
                        contract of sale. The online store will respond to the consumer's complaint in written form or
                        in a form that enables written reproduction within 15 days.
                        <br/><br/><br/>
                        <strong>Direct marketing and processing of personal data</strong><br/><br/>

                        The online store only uses the personal data entered by the purchaser (including name, phone
                        number, address, e-mail and bank details) for processing the order and sending goods to the
                        purchaser. The online store forwards personal data to the transport service provider in order to
                        deliver the goods. The online store sends newsletters and offers to the purchaser's e-mail
                        address only if the purchaser has expressed their wish to receive them by entering their e-mail
                        address on the website and indicating their desire to receive direct mail. The purchaser is able
                        to opt out of offers and newsletters sent to their e-mail address at any time by letting us know
                        via e-mail or by following the instructions in an e-mail containing an offer.
                        <br/><br/><br/>
                        <strong>Settlement of disputed</strong><br/><br/>

                        All complaints made by a purchaser about the online store must be e-mailed to info@fast-iron.com
                        or submitted by calling +372 55639148. If the purchaser and the online store are not able to
                        settle the dispute by agreement, the purchaser may contact the Consumer Dispute Committee. You
                        can review the procedural rules and submit a complaint here. The Consumer Dispute Committee is
                        competent to resolve disputes arising from a contract between a purchaser and the online store.
                        Resolution of disputes by the Consumer Dispute Committee is free of charge for the purchaser. A
                        purchaser may also turn to the dispute resolution bodies of the European Union.
                    </p>
                </div>
            </div>
        </section>;
    }
}