import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import BlogStore from "vanilli-shop-client/dist/stores/blog-store";
import {inject, observer} from "mobx-react";
import Loader from "../base/loader";
import {Link} from "react-router-dom";
import * as React from "react";
import routes from "../../routes";
import DateUtil from "vanilli-shop-client/dist/utils/date-util";

interface IShowroomDetailsProps extends IBaseProps {
    blogStore: BlogStore
}

@inject('blogStore')
@observer
export default class ShowroomDetails extends Base<IShowroomDetailsProps> {
    componentDidMount() {
        const props = this.props;
        props.blogStore.getPostById(props.match.params.id);
    }

    render() {
        return <section className="jumbotron-custom jumbotron-custom-3 bg-white">
            <div className="shell pt-9">
                <Link to={routes.showroom} className="btn btn-back link link-primary link-return"><span
                    className="fa-arrow-left"/>Back</Link>
                <div className="range range-50 pb-5">{!this.props.blogStore.loading ? this.renderPost() :
                    <div className="col-12 text-center">
                        <Loader/>
                    </div>}</div>
            </div>
        </section>;
    }

    private renderPost(): JSX.Element {
        const post = this.props.blogStore.post;
        const published = post?.published && DateUtil.format(post.published);

        return <>
            <div className="col-12 text-center">
                <h2 className="with-divider-red">{post?.title}</h2>
                <h5>{published}</h5>
            </div>
            <div className="col-12">
                <div dangerouslySetInnerHTML={{__html: post?.content || ""}}/>
            </div>
        </>;
    }
}