import React from 'react';
import Home from "./components/home";
import Footer from "./components/footer";
import ShowroomList from "./components/showroom/showroom-list";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import CategoryDetails from "./components/category/category-details";
import ProductDetails from "./components/product-details";
import Cart from "./components/cart/cart";
import Privacy from "./components/privacy";
import Terms from "./components/terms";
import About from "./components/about";
import Contact from "./components/contact";
import Checkout from "./components/checkout/checkout";
import Error404 from "./components/404";
import CheckoutFailure from "./components/checkout/checkout-failure";
import CheckoutSuccess from "./components/checkout/checkout-success";
import SizeGuide from "./components/size-guide";
import AppBase from "vanilli-shop-client/dist/components/app-base";
import ScrollToTop from "vanilli-shop-client/dist/components/scroll-to-top";
import {ToastContainer} from "react-toastify";
import routes from "./routes";
import ShowroomDetails from "./components/showroom/showroom-details";
import NavMenuProvider from "./components/nav-menu/nav-menu-provider";
import Maintenance from "./components/maintenance";

class App extends React.Component {
    render() {
        return <AppBase>
            <BrowserRouter>
                <ScrollToTop/>
                <ToastContainer/>
                <div className="page">
                    {App.renderContent()}
                    {/*{App.renderMaintenance()}*/}
                </div>
            </BrowserRouter>
        </AppBase>
    }

    private static renderContent(): JSX.Element {
        return <>
            <NavMenuProvider/>
            <Switch>
                <Route component={Home} path={routes.root} exact={true}/>
                <Route component={ShowroomList} path={routes.showroom} exact={true}/>
                <Route component={ShowroomDetails} path={`${routes.showroom}/:id`}/>
                <Route component={CategoryDetails} path={`${routes.categories}/:id`}/>
                <Route component={ProductDetails} path={`${routes.products}/:ref`}/>
                <Route component={Cart} path={routes.cart}/>
                <Route component={Privacy} path={routes.privacy}/>
                <Route component={Terms} path={routes.terms}/>
                <Route component={About} path={routes.about}/>
                <Route component={Contact} path={routes.contact}/>
                <Route component={Checkout} path={routes.checkout} exact={true}/>
                <Route component={CheckoutFailure} path={routes.checkoutFailure}/>
                <Route component={CheckoutSuccess} path={routes.checkoutSuccess}/>
                <Route component={SizeGuide} path={routes.sizeGuide}/>
                <Route component={Error404}/>
            </Switch>
            <Footer/>
        </>;
    }

    private static renderMaintenance(): JSX.Element {
        return <Route component={Maintenance}/>;
    }
}

export default App;
