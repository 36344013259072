import * as React from 'react';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import Background2 from '../../assets/images/img2.jpg';
import {inject, observer} from "mobx-react";
import BlogStore from "vanilli-shop-client/dist/stores/blog-store";
import {IBlogPostDto} from "vanilli-shop-client/dist/models/blog";
import {Link} from 'react-router-dom';
import routes from "../../routes";
import Loader from "../base/loader";

interface IShowroomListProps extends IBaseProps {
    blogStore: BlogStore
}

@inject('blogStore')
@observer
export default class ShowroomList extends Base<IShowroomListProps> {
    componentDidMount() {
        this.props.blogStore.getPosts();
    }

    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image bg-image-custom bg-image-center">
            <div className="shell text-center">
                <div className="range range-50 pb-5">
                    <div className="col-12 pt-9">
                        <h2 className="with-divider-red showroom">Showroom</h2>
                        <div className="p text-width-medium">
                            <p className="big text-white">Showroom is a place where we feature all the cool vehicles that have caught our attention.
                            Want to be featured? Send us a request: <a href="mailto:info@fast-iron.com">info@fast-iron.com</a></p>
                        </div>
                    </div>
                    {!this.props.blogStore.loading ? this.renderPostResult() : <div className="cell-xs-12">
                        <Loader textClass="text-white"/>
                    </div>}
                </div>
            </div>
        </section>;
    }

    private renderPostResult(): JSX.Element {
        return this.props.blogStore.posts.length > 0 ? this.renderPosts() : <div className="cell-xs-12">
            <h4 className="text-white">Oops! Seems like we have not posted anything yet!</h4>
        </div>;
    }

    private renderPosts(): JSX.Element {
        return <div
            className="range range-30">{this.props.blogStore.posts.map((post) => ShowroomList.renderPost(post))}</div>;
    }

    private static renderPost(post: IBlogPostDto): JSX.Element {
        const id = post.id;
        const title = post.title;

        return <div key={id} className="col-lg-4 col-md-4 col-sm-6 height-fill">
            <div className="thumbnail-card">
                <Link to={`${routes.showroom}/${id}`}>
                    <img src={post.imgPath} loading="lazy" alt={title} width="370" height="310"
                         className="thumbnail-card-image"/>
                    <div className="thumbnail-card-body">
                        <p className="thumbnail-card-header">{title}</p>
                    </div>
                </Link>
            </div>
        </div>;
    }
}