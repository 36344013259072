import * as React from 'react';
import Background2 from '../assets/images/img2.jpg';

export default class About extends React.Component {
    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image bg-image-custom bg-image-center">
            <div className="shell text-center col-11 col-lg-8 col-md-9 col-sm-10">
                <div className="range range-50 row pt-5 pt-sm-5 pt-md-0 pt-lg-0">
                    <div className="pt-5 pt-sm-5 pt-md-0 pt-lg-0">
                        <h2 className="with-divider-red showroom">About us</h2>
                        <div className="p text-width-medium">
                            <p className="big text-white pt-3">Fast Iron is what happens when you combine badass
                                quality clothing with cool vehicles. Established around 2014 in Estonia,
                                Fast Iron quickly gained recognition among the crowd by becoming a community that has
                                been rapidly growing ever since. Fast Iron Team is always actively looking for new
                                collaborators or simply cool cars for a photoshoot or for an article.
                                If you happen to be an owner of a vehicle that should be covered in our blog, let us
                                know by sending us an e-mail: <a href="mailto:info@fast-iron.com">info@fast-iron.com</a>
                                <br/> <br/>
                                All of our products are and always will be made from sustainable, cruelty
                                free and high quality materials.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}