import React from "react";
import {Link} from "react-router-dom";
import routes from "../../routes";
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {CartItem, ICartItem} from "vanilli-shop-client/dist/models/cart";

interface ICartSideProps {
    cartStore: CartStore,
    onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void,
    loading?: boolean
}

export default class CartSide extends React.Component<ICartSideProps> {
    render() {
        const props = this.props;
        const cartStore = props.cartStore;
        const subTotal = cartStore.calculateSubTotal();
        const shipping = cartStore.calculateShipping();
        const discount = cartStore.calculateDiscount();
        const total = cartStore.calculateTotal();
        const items = cartStore.cartItems;

        return <div className="col-lg-6">
            <div>
                <div className="cell-xs-12 pt-5 pt-lg-0 text-center text-lg-start text-md-start text-sm-start">
                    <h3 className="with-divider-red">Your order</h3>
                </div>
            </div>
            <div className="">
                <div className="">
                    <div className="terms-list-group pt-5">
                        <dl className="terms-list-block terms-list-block-vertical">
                            <dt><strong>Product</strong></dt>
                            {items.map((item) => CartSide.renderItemName(item))}
                        </dl>
                        <dl className="terms-list-block terms-list-block-vertical">
                            <dt><strong>Price</strong></dt>
                            {items.map((item) => CartSide.renderItemPrice(item))}
                        </dl>
                    </div>
                    <dl className="terms-list-block terms-list-block-horizontal">
                        <dt><strong>Subtotal:</strong></dt>
                        <dd>{subTotal.toFixed(2)}€</dd>
                    </dl>
                    {shipping > 0 && <dl className="terms-list-block terms-list-block-horizontal">
                        <dt><strong>Shipping:</strong></dt>
                        <dd>{shipping.toFixed(2)}€</dd>
                    </dl>}
                    {discount && <dl className="terms-list-block terms-list-block-horizontal">
                        <dt><strong>Discount:</strong></dt>
                        <dd>{discount.toFixed(2)}€</dd>
                    </dl>}
                    <dl className="terms-list-block terms-list-block-horizontal">
                        <dt><strong>Total payable:</strong></dt>
                        <dd><strong>{total.toFixed(2)}€</strong></dd>
                    </dl>
                </div>
                <div className="text-center">
                <Link className="p-lg-4 p-md-4 p-sm-3" to={routes.cart}>
                    <button className="btn btn-12 btn-1e2">
                        <i className="fa-shopping-cart pr-1"/>Back to cart
                    </button>
                </Link>
                </div>
                <div className="text-center">
                <a className="p-lg-4 p-md-4 p-sm-3" href="javascript:void(0)">
                    <button className="btn btn-12blk btn-1e2blk" disabled={props.loading}
                            onClick={(e) => props.onConfirm(e)}>Pay<i className="far fa-money-bill-alt pl-1"/></button>
                </a>
                </div>
            </div>
        </div>;
    }

    private static renderItemName(item: ICartItem): JSX.Element {
        return <dd key={item.id}>{item.productName}</dd>;
    }

    private static renderItemPrice(item: ICartItem): JSX.Element {
        const price = CartItem.getCurrentPrice(item);
        return <dd key={item.id}>{price.toFixed(2)}€</dd>;
    }
}