import * as React from 'react';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import Background from '../assets/images/bg.jpg';
import CategoryList from "./category/category-list";
import routes from "../routes";
import {HashLink} from "react-router-hash-link";

export default class Home extends Base<IBaseProps> {
    render() {
        return <div>
            <section style={{backgroundImage: `url(${Background})`}}
                     className="jumbotron-custom jumbotron-custom-3 bg-white bg-image bg-image-custom bg-image-center">
                <div className="jumbotron-custom-content text-center text-white">
                    <div className="shell">
                        <div className="range range-sm-center">
                            <div className="cell-sm-9 cell-md-12 cell-lg-12 pt-10">
                                <p className="large text-white d-none d-lg-block pb-3">Welcome to</p>
                                <div className="pt-3 brand-mobile d-block d-lg-none">
                                    <h4 className="large text-white pb-3 pt-10 pt-3">Welcome to</h4>
                                    <h5 className="text-white">FAST IRON ONLINE STORE</h5>
                                    <p>Lifestyle and clothing brand</p>
                                </div>
                                <div className="d-none d-lg-block">
                                    <h1 className="text-white">FAST IRON</h1>
                                    <p className="text-white">Lifestyle and clothing brand</p>
                                </div>
                                <HashLink className="pt-3" to={routes.categoriesHash}>
                                    <button className="btn btn-1 btn-1e">Shop now!</button>
                                </HashLink>
                                <div id="section05" className="demo text-center mt-10">
                                    <HashLink to={routes.categoriesHash}><span/>Scroll down</HashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CategoryList/>
        </div>;
    }
}