import React from "react";
import {withRouter} from "react-router-dom";
import NavMenu from "./nav-menu";

interface INavMenuProviderProps {
    history: any,
    location: any,
    match: any
}

class NavMenuProvider extends React.Component<INavMenuProviderProps> {
    render() {
        return <NavMenu location={this.props.location}/>;
    }
}

export default withRouter(NavMenuProvider);