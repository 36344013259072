import * as React from 'react';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {inject, observer} from "mobx-react";
import {CartItem, ICartItem} from "vanilli-shop-client/dist/models/cart";
import {Link} from 'react-router-dom';
import routes from "../../routes";
import Coupon from "../coupon";
import CouponStore from "vanilli-shop-client/dist/stores/coupon-store";

interface ICartProps extends IBaseProps {
    cartStore: CartStore,
    couponStore: CouponStore
}

@inject('cartStore', 'couponStore')
@observer
export default class Cart extends Base<ICartProps> {
    render() {
        const props = this.props;
        const cartStore = props.cartStore;
        const couponStore = props.couponStore;
        const discount = cartStore.calculateDiscount();
        const total = cartStore.calculateTotal();

        return <section className="section-xl bg-periglacial-blue pt-11">
            <div className="shell">
            <a href="javascript:void(0)" onClick={() => props.history.goBack()}
               className="btn btn-back link link-primary link-return"><span className="fa-arrow-left"/>back</a>
            <div className="cell-xs-12 text-center">
                <h2 className="with-divider-red">Cart</h2>
                <div className="p text-width-medium pb-5"/>
            </div>

                <div className="table-custom-responsive table-cart-wrap">
                    <table className="table-cart">
                        <thead>
                        <tr>
                            <th/>
                            <th>Item</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>SUBTOTAL</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>{cartStore.cartItems.map((item) => this.renderItem(item))}</tbody>
                    </table>
                </div>
                <div className="table-cart-footer">
                    <div className="row w-100">
                        <div className="col-md-6 col-12">
                            <div className="table-cart-footer-left">
                                <Coupon onApply={async (code) => await couponStore.applyCoupon(code)}
                                        loading={couponStore.saving}/>
                            </div>
                        </div>
                        <div className="col-md-6 text-right">
                            <div className="table-cart-footer-right">
                                <dl className="terms-list-bold">
                                    {discount && <>
                                        <dt>Discount</dt>
                                        <dd>{discount.toFixed(2)}€</dd>
                                        <br/>
                                    </>}
                                    <dt>Total</dt>
                                    <dd>{total.toFixed(2)}€</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-cart-action-panel">
                    <div className="row w-100">
                        <div className="col-12">
                            <Link to={routes.checkout}>
                                <button className="btn btn-12blk btn-1e2blk">Confirm <span className="fa-credit-card pl-1"/>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }

    private renderItem(item: ICartItem): JSX.Element {
        const id = item.id;
        const productName = item.productName;
        const img = item.img || "https://via.placeholder.com/300";
        const link = CartItem.getProductLink(item);
        const price = CartItem.getCurrentPrice(item);
        const total = CartItem.getTotal(item);
        const cartStore = this.props.cartStore;

        return <tr key={id}>
            <td>
                <img src={img} className="cart-image" alt={productName}/>
            </td>
            <td>
                <p className="cart-title">
                    <Link to={link}>{productName}</Link>
                </p>
            </td>
            <td>
                <p className="cart-price">{price.toFixed(2)}€</p>
            </td>
            <td>
                <div className="stepper">
                    <input type="number" className="stepper-input" data-zeros="false" min="1" max="40" value={item.qty}
                           onChange={(e) => cartStore.changeItemQty(Number(e.target.value), id)}/>
                    <span className="stepper-arrow up" onClick={() => cartStore.increaseItemQty(id, 1)}/>
                    <span className="stepper-arrow down" onClick={() => cartStore.decreaseItemQty(id, 1)}/>
                </div>
            </td>
            <td>
                <p className="cart-price">{total.toFixed(2)}€</p>
            </td>
            <td>
                <a href="javascript:void(0)" onClick={() => cartStore.removeItem(id)} className="cart-control"/>
            </td>
        </tr>;
    }
}