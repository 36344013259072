import React from "react";
import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import {inject, observer} from "mobx-react";
import NavbarBrand from '../../assets/images/Banner.png';
import {Link, NavLink} from "react-router-dom";
import routes from "../../routes";
import ProductStore from "vanilli-shop-client/dist/stores/product-store";
import {
    IProductGroupDto,
    IProductGroupProductDto, ProductRefTypeEnum
} from "vanilli-shop-client/dist/models/product";
import Loader from "../base/loader";

interface INavMenuProps extends IBaseProps {
    productStore?: ProductStore
}

@inject('productStore')
@observer
export default class NavMenu extends Base<INavMenuProps> {
    componentDidMount() {
        this.props.productStore?.getProductGroups();
    }

    render() {
        return <header className="page-header">
            <div className="rd-navbar-wrap">
                <nav data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed"
                     data-sm-device-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fullwidth"
                     data-md-device-layout="rd-navbar-fixed" data-lg-device-layout="rd-navbar-fixed"
                     data-lg-layout="rd-navbar-fullwidth" data-stick-up-clone="true" data-md-stick-up-offset="140px"
                     data-lg-stick-up-offset="140px" className="rd-navbar">
                    <div className={this.renderNavClass()}>
                        <div className="rd-navbar-panel">
                            <button id="toggle-original" data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                                    className="rd-navbar-toggle">
                                <span/>
                            </button>
                            <div className="rd-navbar-brand">
                                <Link to={routes.root} className="brand-name">
                                    <div className="brand-desktop">
                                        <img src={NavbarBrand} alt="Fast Iron" width="200" height="auto"/>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div id="toggle-original-elements" className="rd-navbar-nav-wrap">
                            <div className="rd-navbar-nav-inner">
                                <ul className="rd-navbar-nav">
                                    <li className="active">
                                        <NavLink to={routes.root} onClick={() => NavMenu.collapseMenu()}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={routes.about}
                                                 onClick={() => NavMenu.collapseMenu()}>About</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={routes.showroom}
                                                 onClick={() => NavMenu.collapseMenu()}>Showroom</NavLink>
                                    </li>
                                    <li>
                                        <a className="rd-navbar-submenu-toggle">Shop</a>
                                        <ul className="rd-navbar-megamenu">{!this.props.productStore?.loadingProductGroups ? this.renderProductGroups() :
                                            <li className="text-center">
                                                <Loader/>
                                            </li>}</ul>
                                    </li>
                                    <li>
                                        <a className="rd-navbar-submenu-toggle">Cart</a>
                                        <ul className="rd-navbar-dropdown">
                                            <li>
                                                <NavLink className="pb-3" to={routes.cart}
                                                         onClick={() => NavMenu.collapseMenu()}>Cart</NavLink>
                                            </li>
                                            <hr/>
                                            <li>
                                                <NavLink className="pt-3" to={routes.checkout}
                                                         onClick={() => NavMenu.collapseMenu()}>Checkout</NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink to={routes.contact}
                                                 onClick={() => NavMenu.collapseMenu()}>Contact</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>;
    }

    private static collapseMenu() {
        const toggleOriginal = document.getElementById("toggle-original");
        const toggleOriginalElements = document.getElementById("toggle-original-elements");

        if (toggleOriginal) {
            toggleOriginal.classList.remove("active");
        }

        if (toggleOriginalElements) {
            toggleOriginalElements.classList.remove("active");
        }
    }

    private renderProductGroups(): JSX.Element[] {
        return this.props.productStore!.productGroups.map((group) => this.renderProductGroup(group));
    }

    private renderProductGroup(group: IProductGroupDto): JSX.Element {
        const category = group.categoryName;

        return <li key={category} className="px-3">
            <p className="rd-megamenu-header">
                <a>{category}</a>
            </p>
            <ul className="rd-megamenu-list">{group.products.map((product) => NavMenu.renderProduct(product))}</ul>
        </li>;
    }

    private static renderProduct(product: IProductGroupProductDto): JSX.Element {
        let refType = ProductRefTypeEnum.Id;
        const ref = product.ref;

        if (ref === product.code) {
            refType = ProductRefTypeEnum.Code;
        }

        const link = `/products/${ref}?refType=${refType}`;

        return <li key={ref}>
            <Link to={link} onClick={() => NavMenu.collapseMenu()}>{product.name}</Link>
        </li>;
    }

    private renderNavClass(): string {
        let className = "rd-navbar-inner";
        const blackRoutes = [routes.cart, routes.checkout, routes.contact];
        const currentPath = this.props.location.pathname;

        if (blackRoutes.includes(currentPath)) {
            className = `${className} navbar-inner-black`;
        }

        const isDetailView = currentPath.includes(`${routes.categories}/`) || currentPath.includes(`${routes.products}/`) || currentPath.includes(`${routes.showroom}/`);

        if (isDetailView) {
            className = `${className} navbar-inner-black`;
        }

        return className;
    }
}