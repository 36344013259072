import * as React from 'react';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import CartSide from "../cart/cart-side";
import OrderStore from "vanilli-shop-client/dist/stores/order-store";
import {inject, observer} from "mobx-react";
import CountryStore from "vanilli-shop-client/dist/stores/country-store";
import ShippingStore from "vanilli-shop-client/dist/stores/shipping-store";
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {createRef, RefObject} from "react";
import {ICountryDto} from "vanilli-shop-client/dist/models/country";
import {IShippingMethodDto} from "vanilli-shop-client/dist/models/shipping";
import CompanyStore from "vanilli-shop-client/dist/stores/company-store";
import {PaymentProviderEnum} from "vanilli-shop-client/dist/models/payment";
import {CompanyProvider} from "vanilli-shop-client/dist/models/company";

interface ICheckoutProps extends IBaseProps {
    cartStore: CartStore,
    companyStore: CompanyStore,
    countryStore: CountryStore,
    orderStore: OrderStore,
    shippingStore: ShippingStore
}

@inject('cartStore', 'companyStore', 'countryStore', 'orderStore', 'shippingStore')
@observer
export default class Checkout extends Base<ICheckoutProps> {
    private readonly formRef: RefObject<HTMLFormElement>;
    private readonly firstNameRef: RefObject<HTMLInputElement>;
    private readonly lastNameRef: RefObject<HTMLInputElement>;
    private readonly emailRef: RefObject<HTMLInputElement>;
    private readonly phoneRef: RefObject<HTMLInputElement>;
    private readonly addressRef: RefObject<HTMLInputElement>;
    private readonly zipRef: RefObject<HTMLInputElement>;

    async componentDidMount() {
        const props = this.props;
        await props.countryStore.getCountries();
        const companyStore = props.companyStore;
        await companyStore.getPaymentProviders();
        companyStore.selectPaymentProvider(PaymentProviderEnum.MakeCommerce);
    }

    constructor(props: ICheckoutProps) {
        super(props);
        this.formRef = createRef();
        this.firstNameRef = createRef();
        this.lastNameRef = createRef();
        this.emailRef = createRef();
        this.phoneRef = createRef();
        this.addressRef = createRef();
        this.zipRef = createRef();
    }

    render() {
        const props = this.props;
        const countryStore = props.countryStore;
        const shippingStore = props.shippingStore;
        const selectedMethod = shippingStore.selectedMethod;
        const orderStore = props.orderStore;

        return <section className="section-xl bg-periglacial-blue pt-11">
            <div className="shell">
                <div className="pb-5">
                    <a href="javascript:void(0)" onClick={() => props.history.goBack()}
                       className="btn btn-back link link-primary link-return"><span className="fa-arrow-left"/>Back</a>
                    <div className="cell-xs-12 text-center">
                        <h2 className="with-divider-red">Checkout</h2>
                        <div className="p text-width-medium"/>
                    </div>
                </div>
                <div className="row pt-5">
                    <form ref={this.formRef}>
                        <div className="col-lg-6">
                            <h3 className="with-divider-red text-center text-lg-start text-md-start text-sm-start">Billing Details</h3>
                            <div className="range range-sm-bottom range-15">
                                <div className="cell-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="billing-first-name" className="form-label-outside">First Name
                                            *</label>
                                        <input id="billing-first-name" ref={this.firstNameRef} required={true}
                                               type="text" name="first-name" data-constraints="@Required"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="cell-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="billing-last-name" className="form-label-outside">Last Name
                                            *</label>
                                        <input id="billing-last-name" ref={this.lastNameRef} required={true} type="text"
                                               name="last-name" data-constraints="@Required" className="form-control"/>
                                    </div>
                                </div>
                                <div className="cell-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="billing-email" className="form-label-outside">Email Address
                                            *</label>
                                        <input id="billing-email" ref={this.emailRef} required={true} type="email"
                                               name="email" data-constraints="@Email @Required"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="cell-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="billing-phone" className="form-label-outside">Phone *</label>
                                        <input id="billing-phone" ref={this.phoneRef} required={true} type="tel"
                                               name="phone" data-constraints="@Required @Numeric"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="cell-sm-6">
                                    <div className="form-group">
                                        <label className="form-label-outside">Country *</label>
                                        <select className="form-control" required={true} disabled={countryStore.loading}
                                                onChange={async (e) => await this.selectCountry(e)}>
                                            <option value="">-- Choose country --</option>
                                            {countryStore.countries.map((country) => Checkout.renderCountry(country))}
                                        </select>
                                    </div>
                                </div>
                                {countryStore.selectedCountry && <div className="cell-sm-6">
                                    <div className="form-group">
                                        <label className="form-label-outside">Shipping method *</label>
                                        <select className="form-control" required={true}
                                                disabled={shippingStore.loading}
                                                onChange={async (e) => await this.selectShippingMethod(e)}>
                                            <option value="">-- Choose shipping method --</option>
                                            {shippingStore.methods.map((method) => Checkout.renderShippingMethod(method))}
                                        </select>
                                    </div>
                                </div>}
                                {selectedMethod?.provider && <div className="cell-xs-12">
                                    <div className="form-group">
                                        <select className="form-control" required={true}
                                                disabled={shippingStore.loading}
                                                onChange={(e) => shippingStore.selectPickupPoint(e.target.value)}>
                                            <option value="">-- Choose pickup point --</option>
                                            {shippingStore.pickupPoints.map((pickupPoint) => Checkout.renderPickupPoint(pickupPoint))}
                                        </select>
                                    </div>
                                </div>}
                                {selectedMethod?.addressRequired && <>
                                    <div className="cell-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="billing-address" className="form-label-outside">Address
                                                *</label>
                                            <input id="billing-address" ref={this.addressRef} required={true}
                                                   type="text" name="address" data-constraints="@Required"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="cell-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="billing-town" className="form-label-outside">Postcode
                                                *</label>
                                            <input id="billing-town" ref={this.zipRef} required={true} type="text"
                                                   name="postcode" data-constraints="@Required"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                        <CartSide cartStore={props.cartStore} onConfirm={async (e) => await this.initOrderCreate(e)}
                                  loading={orderStore.loading || orderStore.saving}/>
                    </form>
                </div>
            </div>
        </section>;
    }

    private static renderCountry(country: ICountryDto): JSX.Element {
        const id = country.id;
        return <option key={id} value={id}>{country.name}</option>;
    }

    private async selectCountry(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        await this.props.countryStore.selectCountry(id);
    }

    private static renderShippingMethod(method: IShippingMethodDto): JSX.Element {
        const id = method.methodId;
        return <option key={id} value={id}>{method.methodName}</option>;
    }

    private async selectShippingMethod(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        await this.props.shippingStore.selectMethod(id);
    }

    private static renderPickupPoint(pickupPoint: string): JSX.Element {
        return <option key={pickupPoint} value={pickupPoint}>{pickupPoint}</option>;
    }

    private async initOrderCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            await this.createOrder();
        }
    }

    private async createOrder() {
        const order = {
            customer: {
                firstName: this.firstNameRef.current?.value,
                lastName: this.lastNameRef.current?.value,
                email: this.emailRef.current?.value,
                phone: this.phoneRef.current?.value
            },
            shipping: {
                address: this.addressRef.current?.value,
                zip: this.zipRef.current?.value
            }
        };

        const props = this.props;
        const result = await props.orderStore.createOrder(order);

        if (result) {
            const paymentProvider = props.companyStore.selectedPaymentProvider;

            if (paymentProvider?.name === PaymentProviderEnum.MakeCommerce) {
                const shopId = CompanyProvider.getShopId(paymentProvider);
                const mcUrl = `${process.env.REACT_APP_MAKE_COMMERCE_ENDPOINT}?shop=${shopId}&amount=${result.total}&reference=${result.orderCode}`;
                window.open(mcUrl, "_self");
            }
        }
    }
}