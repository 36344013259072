import * as React from 'react';
import {Link} from 'react-router-dom';
import Background2 from '../../assets/images/img2.jpg';
import routes from "../../routes";

export default class CheckoutFailure extends React.Component {
    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image bg-image-custom bg-image-center">
            <div className="shell text-center">
                <div className="range range-50">
                    <div className="col-12 pt-5">
                        <h2 className="with-divider-red showroom">Payment failed!</h2>
                        <div className="p text-width-medium">
                            <p className="big text-white">Looks like something went wrong with the payment. Try
                                again!</p>
                        </div>
                        <Link className="p-lg-4 p-md-4 p-sm-3" to={routes.root}>
                            <button className="btn btn-12 btn-1e3"><span className="fa-arrow-left pr-1"/> Home</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>;
    }
}