import * as React from 'react';
import Background2 from '../assets/images/img2.jpg';
import NavbarBrand from "../assets/images/Banner.png";

export default class Maintenance extends React.Component {
    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image bg-image-custom bg-image-center p-vh-50">
            <div className="shell text-center">
                <div className="range range-50">
                    <div className="pt-5 row justify-content-center">
                        <div className="brand-desktop-white img-invert">
                            <img src={NavbarBrand} alt="Fast Iron" width="300" height="auto"/>
                        </div>
                        <h2 className="with-divider-red showroom pt-5">Website under construction</h2>
                        <div className="p text-width-medium col-10">
                            <p className="big text-white">Hi there! Our website is currently under construction, but will be back shortly.
                            Please try again later. In case you have any questions, feel free to contact us directly through Facebook / IG
                                or send an e-mail to info@fast-iron.com. Thanks! :) </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}