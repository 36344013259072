import * as React from 'react';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import ProductStore from "vanilli-shop-client/dist/stores/product-store";
import {inject, observer} from "mobx-react";
import {IProductDto, Product} from "vanilli-shop-client/dist/models/product";
import {Link} from 'react-router-dom';
import Loader from "../base/loader";
import CategoryStore from "vanilli-shop-client/dist/stores/category-store";
import routes from "../../routes";
import {HashLink} from "react-router-hash-link";

interface ICategoryDetailsProps extends IBaseProps {
    categoryStore: CategoryStore,
    productStore: ProductStore
}

@inject('categoryStore', 'productStore')
@observer
export default class CategoryDetails extends Base<ICategoryDetailsProps> {
    componentDidMount() {
        const props = this.props;
        const id = props.match.params.id;
        props.categoryStore.getCategoryById(id);

        const request = {
            categoryId: id,
            active: true
        };

        props.productStore.searchProducts(request);
    }

    render() {
        return <section className="section-xl bg-white text-center pt-11">
            <div className="shell">
                <div className="text-left">
                    <HashLink to={routes.categoriesHash} className="btn btn-back link link-primary link-return"><span
                        className="fa-arrow-left"/>Back</HashLink>
                </div>
                <div className="range range-50 text-center">
                    <div className="cell-xs-12">
                        {this.renderCategory()}
                        <div className="p text-width-medium">
                            <p className="big">Choose desired product below.</p>
                        </div>
                        <i className="fas fa-long-arrow-alt-down"/>
                    </div>
                    <div className="cell-xs-12">{!this.props.productStore.loading ? this.renderProducts() :
                        <Loader/>}</div>
                </div>
            </div>
        </section>;
    }

    private renderCategory(): JSX.Element {
        const categoryStore = this.props.categoryStore;
        return !categoryStore.loading ? <h2 className="with-divider-red">{categoryStore.category?.name}</h2> :
            <Loader/>;
    }

    private renderProducts(): JSX.Element {
        return <div
            className="range range-30 row">{this.props.productStore.productList.entities.map((product) => CategoryDetails.renderProduct(product))}</div>;
    }

    private static renderProduct(product: IProductDto): JSX.Element {
        const link = Product.getLink(product);
        const img = product.img || "https://via.placeholder.com/300";
        const name = product.name;

        return <div key={product.ref} className="col-lg-3 col-md-4 col-6 height-fill">
            <div className="thumbnail-card">
                <Link to={link}>
                    <img src={img} className="thumbnail-card-image" alt={name} loading="lazy"/>
                    <div className="thumbnail-card-body">
                        <p className="thumbnail-card-header">{name}</p>
                    </div>
                </Link>
            </div>
        </div>;
    }
}