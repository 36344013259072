import * as React from 'react';
import Background2 from "../assets/images/img2.jpg";

export default class Privacy extends React.Component {
    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image">
            <div className="cell-xs-12 text-center pt-10 text-white pb-5">
                <h2 className="with-divider-red pt-5 text-white">Privacy policy</h2>
                <div className="p text-width-medium">
                    <p className="big text-justify pt-5">
                        <strong>PROCESSING OF PERSONAL DATA</strong><br/><br/>

                        The controller of the personal data of the online shop is Fast Iron (https://fast-iron.com
                        registry code 14453633), located at Linnu tn 41/4 Tartu, Tartumaa 50416, phone +372 55639148 and
                        email info@fast-iron.com. Fast Iron OÜ is responsible for the personal data inserted by the
                        customer and forwards the aforementioned data to Maksekeskus AS for billing.
                        <br/><br/><br/>
                        <strong>What personal data are processed</strong><br/>
                        <br/>
                        - name, phone number and email address; - delivery address; - cost of goods and services and
                        data related to payments (purchase history); - customer support data.
                        <br/><br/><br/>
                        <strong>Why personal data are processed</strong><br/><br/>

                        Personal data are used to manage the customer's orders and deliver goods.
                        <br/>
                        Purchase history details (date of purchase, goods, quantity, customer's data) are used for
                        preparing summaries of goods and services purchased and for analysing customer preferences.
                        <br/>
                        Personal data such as email, phone number and the customer's name are processed for the
                        provision of the online shop as an information society service and for web use statistics.
                        <br/><br/><br/>
                        <strong>Legal grounds</strong><br/><br/>

                        Personal data are processed for the purpose of performing a contract concluded with the
                        customer.
                        <br/>
                        Personal data are processed for performing legal obligations (such as accounting and the
                        settlement of consumer complaints).
                        <br/><br/><br/>
                        <strong>Recipients of personal data</strong><br/><br/>

                        Personal data are transmitted to the customer support of the online shop for managing purchases
                        and purchase history and for settling any problems that the customers may have.

                        The name, phone number and email address are transmitted to the transport service provider
                        selected by the customer. When the goods are delivered by a courier, the customer's address is
                        also transmitted together with the contact details.

                        If the accounts of the web shop are kept by a service provider, the personal data are
                        transmitted to the service provider for performing accounting operations.
                    </p>
                </div>
            </div>
        </section>;
    }
}