import * as React from 'react';
import {Link} from 'react-router-dom';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import FooterBrand from '../assets/images/Banner.png';
import routes from "../routes";
import {HashLink} from "react-router-hash-link";

export default class Footer extends Base<IBaseProps> {
    render() {
        return <footer className="page-footer page-footer-variant-3">
            <div className="shell">
                <div className="range range-50">
                    <div className="cell-sm-6 cell-md-4">
                        <h3>Navigation</h3>
                        <div className="divider divider-conch"/>
                        <ul className="footer-navigation row footer-navigation-vertical">
                            <li className="col-xs-6">
                                <Link to={routes.root}>Home</Link>
                            </li>
                            <li className="col-xs-6">
                                <Link to={routes.about}>About</Link>
                            </li>
                            <li className="col-xs-6">
                                <Link to={routes.showroom}>Showroom</Link>
                            </li>
                            <li className="col-xs-6">
                                <HashLink to={routes.categoriesHash}>Shop</HashLink>
                            </li>
                            <li className="col-xs-6">
                                <Link to={routes.sizeGuide}>Size guide</Link>
                            </li>
                            <li className="col-xs-6">
                                <Link to={routes.contact}>Contact</Link>
                            </li>
                            <li className="col-xs-6">
                                <Link to={routes.terms} target="_blank">Terms and conditions</Link>
                            </li>
                            <li className="col-xs-6">
                                <Link to={routes.privacy} target="_blank">Privacy policy</Link>
                            </li>
                        </ul>
                        <ul className="footer-navigation row footer-navigation-vertical">
                            <li className="col-xs-6 ">
                                <a>Payment methods:</a>
                            </li>
                            <li className="col-xs-6"><i className="fab fa-cc-visa"/> <i
                                className="fab fa-cc-mastercard"/></li>
                        </ul>
                    </div>
                    <div className="cell-sm-6 cell-md-5">
                        <h3>About us</h3>
                        <div className="divider divider-conch"/>
                        <div className="text-highlighted-wrap">
                            <p className="text-highlighted text-small">Fast Iron is a well established lifestyle brand
                                from Estonia that has been focusing on fast cars, cool vehicles and even cooler clothing
                                since 2014. "Keep it Fast 'til the end".</p>
                        </div>
                    </div>
                    <div className="cell-sm-6 cell-md-3 footer-brand">
                        <h3>Follow Us</h3>
                        <div className="divider divider-conch"/>
                        <div className="pt-1 pb-3">
                            <img className="" src={FooterBrand} alt="Fast Iron"/>
                        </div>
                        <ul className="inline-list inline-list-xs">
                            <li>
                                <a href="//www.facebook.com/fastironclothing" target="_blank" rel="noopener noreferrer"
                                   className="icon icon-xs-outline icon-wild-sand-filled icon-circle fa-facebook"/>
                            </li>
                            <li>
                                <a href="//www.youtube.com/user/tafenau24" target="_blank" rel="noopener noreferrer"
                                   className="icon icon-xs-outline icon-wild-sand-filled icon-circle fa-youtube"/>
                            </li>
                            <li>
                                <a href="//www.instagram.com/fast.iron/" target="_blank" rel="noopener noreferrer"
                                   className="icon icon-xs-outline icon-wild-sand-filled icon-circle fa-instagram"/>
                            </li>
                        </ul>
                    </div>
                    <div className="cell-lg-12">
                        <p><span id="copyright-year text-black"/> &#169; Fast Iron OÜ&nbsp;</p>
                    </div>
                </div>
            </div>
        </footer>;
    }
}