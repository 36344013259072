import * as React from 'react';
import Background2 from '../assets/images/img2.jpg';
import routes from "../routes";
import {HashLink} from "react-router-hash-link";

export default class SizeGuide extends React.Component {
    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image bg-image-custom bg-image-center">
            <div className="shell text-center ">
                <div className="range range-50 row">
                    <div className="col-12">
                        <h2 className="with-divider-red showroom pt-8">Size guide</h2>
                        <div className="row justify-content-center pt-5">
                            <div className="p text-width-medium col-9 col-sm-8 col-md-7 col-lg-6 size">
                                <table className="table table-dark">
                                    <thead>
                                    <tr>
                                        <th scope="col">Size</th>
                                        <th scope="col">(Chest, cm)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">S</th>
                                        <td>34 / 36</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">M</th>
                                        <td>38 / 40</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">L</th>
                                        <td>42 / 44</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">XL</th>
                                        <td>46 / 48</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">XXL</th>
                                        <td>50 / 52</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <HashLink className="p-lg-4 p-md-4 p-sm-3" to={routes.categoriesHash}>
                                <button className="btn btn-12 btn-1e3">Shop <span className="fa-shopping-basket pl-1"/>
                                </button>
                            </HashLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
}