import React from "react";

interface ILoaderProps {
    className?: string,
    textClass?: string
}

export default class Loader extends React.Component<ILoaderProps> {
    render() {
        const props = this.props;

        return <div className={props.className}>
            <h4 className={props.textClass}>Loading...</h4>
        </div>;
    }
}