const routes = {
    root: "/",
    about: "/about",
    cart: "/cart",
    categories: "/categories",
    categoriesHash: "/#categories",
    checkout: "/checkout",
    checkoutFailure: "/checkout/failure",
    checkoutSuccess: "/checkout/success",
    contact: "/contact",
    privacy: "/privacy",
    products: "/products",
    showroom: "/showroom",
    sizeGuide: "/size-guide",
    terms: "/terms",
    maintenance: "/maintenance"
}

export default routes