import * as React from 'react';
import routes from "../routes";
import {importScript, removeScript} from "vanilli-shop-client/dist/components/import-script";

export default class Contact extends React.Component {
    componentDidMount() {
        removeScript("/js/script.js");
        importScript("/js/script.js");
    }

    render() {
        return <div>
            <section className="section-xl bg-periglacial-blue pt-11">
                <div className="cell-xs-12 text-center">
                    <h2 className="with-divider-red">Contact</h2>
                    <div className="p text-width-medium"/>
                </div>
            </section>
            <section className="box-wrap box-wrap-md bg-white">
                <div className="box-wrap-content justify-content-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="box-contacts box-contacts-horizontal box-wrap-content-interactive">
                                    <div className="row box-contacts-col align-items-end">
                                        <div className="box-contacts-block">
                                            <h3>Company address</h3>
                                            <address>Linnu tn 41/4 Tartu, Tartumaa 50416</address>
                                        </div>
                                        <div className="divider divider-nebula"/>
                                        <div className="box-contacts-block">
                                            <h3>Opening Hours</h3>
                                            <dl className="box-contacts-terms-list">
                                                <dt className="pb-3">Online 24/7</dt>
                                                <dt>Offline store temporarily closed.</dt>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="box-contacts-col box-contacts-right">
                                        <div className="box-contacts-block">
                                            <h3>Get In Touch</h3>
                                            <p>Leave your name and phone number, and we’ll contact you.</p>
                                            <form data-form-output="form-output-global" data-form-type="contact"
                                                  method="post"
                                                  action={`${process.env.REACT_APP_API_ENDPOINT}/email/inquiry/client/form`}
                                                  className="rd-mailform" data-success-redirect={routes.root}>
                                                <div className="form-group">
                                                    <label htmlFor="contact-full-name" className="form-label-outside">Full
                                                        Name</label>
                                                    <input id="contact-full-name" type="text" name="name"
                                                           data-constraints="@Required" className="form-control"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="contact-phone"
                                                           className="form-label-outside">Telephone</label>
                                                    <input id="contact-phone" type="tel" name="phone"
                                                           data-constraints="@Required @Numeric"
                                                           className="form-control"/>
                                                </div>
                                                <div className="text-center">
                                                    <button className="btn btn-12blk btn-1e2blk" type="submit">Send
                                                        request<span className="fa-paper-plane pl-1"/></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="snackbars" id="form-output-global"/>
        </div>;
    }
}