import * as React from 'react';
import {default as Base, IBaseProps} from 'vanilli-shop-client/dist/components/base';
import Background2 from '../../assets/images/img2.jpg';
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {inject, observer} from "mobx-react";
import {Link} from 'react-router-dom';
import routes from "../../routes";

interface ICheckoutSuccessProps extends IBaseProps {
    cartStore: CartStore
}

@inject('cartStore')
@observer
export default class CheckoutSuccess extends Base<ICheckoutSuccessProps> {
    componentDidMount() {
        this.props.cartStore.resetCart();
    }

    render() {
        return <section style={{backgroundImage: `url(${Background2})`}}
                        className="jumbotron-custom jumbotron-custom-3 bg-white bg-image bg-image-custom bg-image-center">
            <div className="shell text-center">
                <div className="range range-50">
                    <div className="col-12 pt-5">
                        <h2 className="with-divider-success showroom">Thank you!</h2>
                        <div className="p text-width-medium">
                            <p className="big text-white">Your order has been placed. Check your e-mail for receipt.</p>
                        </div>
                        <Link className="p-lg-4 p-md-4 p-sm-3" to={routes.root}>
                            <button className="btn btn-12 btn-1e3"><span className="fa-arrow-left pr-1"/> Home</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>;
    }
}